import { computed } from "vue";

export default function useProfileColor(colorNum) {
  const PROFILE_COLORS = [
    "#F3C5CE",
    "#FFD5A4",
    "#B9B8FF",
    "#DCD1FF",
    "#FFF1A9",
    "#FFC9C2",
    "#B4CAFE",
    "#CDD0F0",
    "#F3C5CE",
    "#FFD5A4",
  ];

  const profileColor = computed(() => {
    return PROFILE_COLORS[colorNum];
  });

  return {
    profileColor,
  };
}
