<template>
  <ButtonLink
    :label="isLess ? 'Mniej' : 'Więcej'"
    :size="size"
    :isDisabled="isDisabled"
  />
</template>

<script>
export default {
  props: {
    isLess: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "medium",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
