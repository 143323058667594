import { onBeforeUnmount, onMounted, ref } from "vue";

export default function useViewportUnits() {
  const calcVw = () => {
    return window.innerWidth * 0.01;
  };
  const calcVh = () => {
    return window.innerHeight * 0.01;
  };

  const vw = ref(calcVw());
  const vwString = ref(calcVw() + "px");
  const vh = ref(calcVh());
  const vhString = ref(calcVh() + "px");

  const onResize = () => {
    vh.value = calcVh();
    vhString.value = calcVh() + "px";
    vw.value = calcVw();
    vwString.value = calcVw() + "px";
  };

  onMounted(() => {
    window.addEventListener("resize", onResize, { passive: true });
  });

  onBeforeUnmount(() => {
    window.removeEventListener("resize", onResize, { passive: true });
  });

  return { vw, vwString, vh, vhString };
}
