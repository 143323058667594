import { ref, computed } from "vue";

export default function useBlockTextExpandable({
  textRef,
  maxLengthRef = 200,
}) {
  const isBlockExpanded = ref(false);
  const isTextOverflow = computed(() => {
    return textRef.value.length > maxLengthRef.value;
  });

  const readyText = computed(() => {
    if (isTextOverflow.value) {
      if (isBlockExpanded.value) {
        return textRef.value;
      } else {
        return textRef.value.slice(0, maxLengthRef.value).trim() + "…";
      }
    }
    return textRef.value;
  });

  // Style: block border radius
  const blockBorderRadius = computed(() => {
    return isTextOverflow.value && !isBlockExpanded.value ? "20px" : "10px";
  });

  return {
    isBlockExpanded,
    isTextOverflow,
    readyText,
    blockBorderRadius,
  };
}
