<template>
  <div class="layout-view-block-11">
    <div class="x1 layout-view-block-11__x1">
      <slot name="left"></slot>
    </div>
    <div class="x1 layout-view-block-11__x1">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.layout-view-block-11 {
  width: 100%;

  display: flex;
  column-gap: var(--layout-view-block-11--column-gap);
}
.layout-view-block-11__x1 {
  display: flex;
  flex-flow: column;

  width: calc((100% - var(--layout-view-block-11--column-gap)) / 2);
}

@media (max-width: 1200px) {
  .layout-view-block-11 {
    flex-wrap: wrap;
  }
  .layout-view-block-11__x1 {
    width: 100%;

    flex-grow: unset;
  }
}
</style>
