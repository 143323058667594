<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
  >
    <path
      d="M14.0617 26.9998L12.4492 25.3873L19.8742 17.9623L12.4492 10.5373L14.0617 8.9248L23.0992 17.9623L14.0617 26.9998Z"
      fill="black"
    />
  </svg>
</template>

<style scoped>
.svg-icon path {
  fill: var(--default-font-color);
}
</style>
