<template>
  <div class="block-comment">
    <p v-html="readyText"></p>
    <ButtonLinkMore
      v-if="isTextOverflow"
      :isLess="isBlockExpanded"
      :size="'small'"
      @click="isBlockExpanded = !isBlockExpanded"
    />
  </div>
</template>

<script>
import { computed, toRef } from "@vue/reactivity";
import ButtonLinkMore from "@/components/UI/ButtonLinkMore";
import useBlockTextExpandable from "@/composables/useBlockTextExpandable";

export default {
  components: { ButtonLinkMore },
  props: {
    text: String,
    maxLength: { type: Number, default: 200 },
  },
  setup(props) {
    const { isBlockExpanded, isTextOverflow, readyText, blockBorderRadius } =
      useBlockTextExpandable({
        textRef: toRef(props, "text"),
        maxLengthRef: toRef(props, "maxLength"),
      });

    const blockPadding = computed(() => {
      return isTextOverflow.value
        ? "20px 30px 7px 30px"
        : "20px 30px 20px 30px";
    });

    return {
      isBlockExpanded,
      isTextOverflow,
      readyText,
      blockBorderRadius,
      blockPadding,
    };
  },
};
</script>

<style scoped>
.block-comment {
  padding: v-bind(blockPadding);

  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  /* row-gap: 10px; */

  background-color: #c7ede6;
  border-radius: v-bind(blockBorderRadius);
}
</style>
