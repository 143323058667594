<template>
  <div class="container-chips">
    <BaseGroupChipItem
      v-for="item in items"
      :key="item.slug"
      :label="item.label"
    />
    <BaseGroupChipItem
      v-for="item in itemsCrossed"
      :key="item.slug"
      :label="item.label"
      :crossed="true"
    />
  </div>
</template>

<script>
import BaseGroupChipItem from "./BaseGroupChipItem.vue";
export default {
  components: { BaseGroupChipItem },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemsCrossed: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.container-chips {
  width: 100%;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 20px;
}

@media (max-width: 1200px) {
  .container-chips {
    width: 100%;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    column-gap: 13px;
    row-gap: 13px;
  }
}
</style>
