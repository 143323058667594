<template>
  <BaseButton class="button" :label="''">
    <SvgIconsNavigateNext24 v-if="size === 'small'" />
    <SvgIconsNavigateNext36 v-else />
  </BaseButton>
</template>

<script>
import BaseButton from "@/components/UI/Base/BaseButton.vue";

import SvgIconsNavigateNext24 from "@/components/Svg/Icons/SvgIconsNavigateNext24.vue";
import SvgIconsNavigateNext36 from "@/components/Svg/Icons/SvgIconsNavigateNext36.vue";

export default {
  components: { BaseButton, SvgIconsNavigateNext24, SvgIconsNavigateNext36 },

  props: {
    size: {
      type: String,
      default: "medium",
    },
  },
};
</script>

<style scoped>
.button {
  opacity: 0.4;
}
.button:hover {
  opacity: 1;
}
@media (max-width: 1200px) {
  .button {
    opacity: 1;
  }
}
</style>
