<template>
  <svg
    width="39"
    height="32"
    viewBox="0 0 39 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
  >
    <path
      d="M38.2812 23.6387C38.2812 25.6699 37.5195 27.4473 35.9961 28.9707C34.5573 30.4095 32.7799 31.1289 30.6641 31.1289C28.7174 31.1289 26.9824 30.3672 25.459 28.8438C23.9355 27.3203 23.0046 25.5853 22.666 23.6387C22.0736 19.5762 22.793 15.4714 24.8242 11.3242C26.8555 7.17708 29.8177 3.96094 33.7109 1.67578C34.7266 1.08333 35.4883 1.12565 35.9961 1.80273C36.5885 2.39518 36.5462 2.94531 35.8691 3.45312C34.3457 4.63802 32.9492 6.16146 31.6797 8.02344C30.4948 9.88542 29.6908 11.8743 29.2676 13.9902C28.929 15.6829 29.2676 16.5293 30.2832 16.5293C32.8223 16.5293 34.7689 17.2487 36.123 18.6875C37.5618 20.0417 38.2812 21.6921 38.2812 23.6387ZM17.0801 23.6387C17.0801 25.6699 16.3184 27.4473 14.7949 28.9707C13.3561 30.4095 11.5788 31.1289 9.46289 31.1289C7.51628 31.1289 5.78125 30.3672 4.25781 28.8438C2.73438 27.3203 1.80339 25.5853 1.46484 23.6387C0.872396 19.5762 1.5918 15.4714 3.62305 11.3242C5.6543 7.17708 8.61654 3.96094 12.5098 1.67578C13.5254 1.08333 14.3294 1.12565 14.9219 1.80273C15.5143 2.39518 15.4297 2.94531 14.668 3.45312C13.1445 4.63802 11.748 6.16146 10.4785 8.02344C9.29362 9.88542 8.48958 11.8743 8.06641 13.9902C7.72786 15.6829 8.06641 16.5293 9.08203 16.5293C11.6211 16.5293 13.5677 17.2487 14.9219 18.6875C16.3607 20.0417 17.0801 21.6921 17.0801 23.6387Z"
      fill="#000000"
    />
  </svg>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style scoped>
.svg-icon path {
  fill: var(--default-font-color);
  /* fill: #16939b; */
  fill-opacity: 0.7;
}
</style>
