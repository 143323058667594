<template>
  <BaseButton class="button" :label="''">
    <SvgIconsNavigateBack24 v-if="size === 'small'" />
    <SvgIconsNavigateBack36 v-else />
  </BaseButton>
</template>

<script>
import BaseButton from "@/components/UI/Base/BaseButton.vue";

import SvgIconsNavigateBack24 from "@/components/Svg/Icons/SvgIconsNavigateBack24.vue";
import SvgIconsNavigateBack36 from "@/components/Svg/Icons/SvgIconsNavigateBack36.vue";

export default {
  components: { BaseButton, SvgIconsNavigateBack24, SvgIconsNavigateBack36 },

  props: {
    size: {
      type: String,
      default: "medium",
    },
  },
};
</script>

<style scoped>
.button {
  opacity: 0.4;
}
.button:hover {
  opacity: 1;
}
@media (max-width: 1200px) {
  .button {
    opacity: 1;
  }
}
</style>
