<template>
  <div class="block-fee">
    <BaseGroupChipItem
      :label="readyFeeHour"
      class="fee-hour block-fee__fee-hour"
    />
    <BaseGroupChipItem
      v-if="feeMonth"
      :label="readyFeeMonth"
      class="fee-month block-fee__fee-month"
    />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

import BaseGroupChipItem from "./UI/Base/BaseGroupChipItem.vue";

export default {
  components: { BaseGroupChipItem },

  props: {
    feeHour: Number,
    feeMonth: Number,
  },

  setup(props) {
    const readyFeeHour = computed(() => {
      return `<b>${props.feeHour}</b> zł/godzinę`;
    });

    const readyFeeMonth = computed(() => {
      return props.feeMonth ? `<b>${props.feeMonth}</b> zł/miesiąc` : null;
    });
    return { readyFeeMonth, readyFeeHour };
  },
};
</script>

<style scoped>
.block-fee {
  width: 100%;

  display: flex;
  column-gap: 40px;
  justify-content: center;
}
.block-fee .block-fee__fee-hour {
  background-color: #bfb3f9;
}
.block-fee .block-fee__fee-month {
  background-color: transparent;
}

@media (max-width: 1200px) {
  .block-fee {
    width: 100%;

    display: flex;
    column-gap: 10px;
    justify-content: center;
  }
  .block-fee .block-fee__fee-hour {
    width: var(--button-width);
  }
  .block-fee .block-fee__fee-month {
    width: var(--button-width);
  }
}
</style>
