<template>
  <div class="group-item-icon-label-round">
    <BaseGroupIconLabelRoundItem
      v-for="item in items"
      :key="item.slug"
      :icon-src="item.iconSrc"
      :label="item.label"
      :is-present="item.isPresent"
    />
  </div>
</template>

<script>
import BaseGroupIconLabelRoundItem from "@/components/UI/Base/BaseGroupIconLabelRoundItem.vue";

export default {
  components: { BaseGroupIconLabelRoundItem },

  props: {
    items: Array,
  },
};
</script>

<style scoped>
.group-item-icon-label-round {
  width: 100%;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .group-item-icon-label-round {
    display: flex;
    flex-flow: row wrap;
    column-gap: 10px;
    row-gap: 10px;
  }
}
</style>
