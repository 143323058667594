<template>
  <div class="item-icon-label-round" :present="isPresent">
    <div class="icon-wrapper item-icon-label-round__icon-wrapper">
      <img :src="iconSrc" :alt="label" class="icon icon-wrapper__icon" />
    </div>
    <label class="label item-icon-label-round__label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    isPresent: {
      type: Boolean,
      default: false,
    },
    iconSrc: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.item-icon-label-round {
  width: min-content;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 10px;
}
.item-icon-label-round[present="false"] .item__icon-wrapper {
  background-color: #ececec;
}
.item-icon-label-round[present="false"] .item__label {
  opacity: 0.3;
}
.item-icon-label-round[present="false"] .icon-wrapper__icon {
  opacity: 0.5;
}
.item-icon-label-round__icon-wrapper {
  width: 100px;
  height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(199, 237, 230, 1);
  border-radius: 50px;
}
.icon-wrapper__icon {
  width: auto;
  height: 45%;
}

.item-icon-label-round__label {
  text-align: center;
  font-size: 14px;
}

@media (max-width: 1200px) {
  .item-icon-label-round__icon-wrapper {
    width: calc((var(--layout-default--width) - 10px * 3) / 4);
    height: calc((var(--layout-default--width) - 10px * 3) / 4);
  }
  .item-icon-label-round__label {
    font-size: 12px;
  }
}
</style>
