import timingFunctions from "@/helpers/timingFunctions";
import { reactive, ref } from "vue";

export default function usePhotoGallery(
  galleryRef,
  {
    modeInitial = "preview",
    isOpenedInitial = true,
    frameGap,
    frameAmountRef,
    currentImageElementRef,
    framePreviewWidthRef = 0,
  }
) {
  const _currentFrameIndex = ref(0);
  const _mode = ref(modeInitial);
  const _isOpened = ref(isOpenedInitial);

  const galleryController = reactive({
    isOpened: _isOpened,
    mode: _mode,
    frameAmount: frameAmountRef,
    frameGap,
    currentImageElement: currentImageElementRef,
    currentFrameIndex: _currentFrameIndex,
    framePreviewWidth: framePreviewWidthRef,
    isScrollDisabled: false,
    moveTo(scrollLeft) {
      galleryRef.value.scrollLeft = scrollLeft;
    },
    animateTo(scrollLeft) {
      this.abortAnimation = _animateGalleryTo({
        startScrollLeft: galleryRef.value.scrollLeft,
        finishScrollLeft: scrollLeft,
      });
    },
    moveToFrame(num, { animate = false } = {}) {
      const frameNum = _getValidatedFrameNum(num);
      if (frameNum !== num) {
        return;
      }
      _updateCurrentFrameIndex(frameNum);
      const scrollLeft = _getFrameScrollLeftByFrameNum(frameNum);
      if (animate) {
        return this.animateTo(scrollLeft);
      }
      return this.moveTo(scrollLeft);
    },
    moveToPrev({ animate = false } = {}) {
      return this.moveToFrame(this.currentFrameIndex - 1, { animate });
    },
    moveToNext({ animate = false } = {}) {
      return this.moveToFrame(this.currentFrameIndex + 1, { animate });
    },
    switchMode(newMode) {
      if (this.mode === newMode) {
        return;
      }
      const gallery = galleryRef.value;
      const currentScrollLeft = _getFrameScrollLeft("closest");
      if (
        currentScrollLeft !== gallery.scrollLeft
        // && Math.abs(gallery.scrollLeft - currentScrollLeft) > 100
      ) {
        // Stop switching in case of inertia scrolling AKA 'animation'
        return;
      }
      // const frameWidth = gallery.parentElement.parentElement.offsetWidth;
      const windowWidth = document.documentElement.clientWidth;
      const shiftPerFrame = windowWidth - this.framePreviewWidth;
      const shiftScrollLeftAbs =
        shiftPerFrame * _calcCurrentFrameIndex(gallery);

      gallery.classList.remove("scrollSnap");

      let finishScrollLeft;
      if (newMode === "full-screen") {
        const newGalleryWidth = windowWidth * this.frameAmount;
        this._increaseGalleryWidthBy(newGalleryWidth - gallery.scrollWidth);
        finishScrollLeft = currentScrollLeft + shiftScrollLeftAbs;
      }
      if (newMode === "preview") {
        finishScrollLeft = currentScrollLeft - shiftScrollLeftAbs;
      }
      this.moveTo(finishScrollLeft);
      this.mode = newMode;
      setTimeout(() => {
        this._decreaseGalleryWidthToDefault();
        gallery.classList.add("scrollSnap");
      }, 100);
    },
    open(frameNum) {
      this.moveToFrame(frameNum);
      this.isOpened = true;
    },
    close() {
      this.isOpened = false;
    },
    openFullScreen() {
      this.switchMode("full-screen");
    },
    closeFullScreen() {
      this.switchMode("preview");
    },
    disableScrolling() {
      if (this.isScrollDisabled) {
        return;
      }
      this.isScrollDisabled = true;
      galleryRef.value.classList.add("noscroll");
    },
    enableScrolling() {
      if (!this.isScrollDisabled) {
        return;
      }
      this.isScrollDisabled = false;
      galleryRef.value.classList.remove("noscroll");
    },
    _increaseGalleryWidthBy(num) {
      // TODO: generate this wider element
      const widerEl = document.getElementById("galleryWider");
      widerEl.style.minWidth = `${num + 100}px`;
    },
    _decreaseGalleryWidthToDefault() {
      const widerEl = document.getElementById("galleryWider");
      widerEl.style.minWidth = "0px";
    },
  });

  const gestureController = reactive({
    gesture: null, // 'verticalScroll', 'horizontalScroll', 'zoom'
    startTime: null,
    touchesCache: [],
    isDetecting: false,
    zoomStartCenterX: null,
    zoomStartCenterY: null,
    zoomStartDistance: null,
    reset() {
      this.touchesCache = [];
      this.startTime = null;
      this.gesture = null;
      this.zoomStartCenterX = null;
      this.zoomStartCenterY = null;
      this.zoomStartDistance = null;
    },
    isTouchInCache(touch) {
      return this.touchesCache.some(
        (touchCached) => touchCached.identifier === touch.identifier
      );
    },
    findTouchIndex(touch) {
      return this.touchesCache.findIndex(
        (touchCached) => touchCached.identifier === touch.identifier
      );
    },
    cacheTouch(touch) {
      if (this.isTouchInCache(touch)) {
        return;
      }
      if (this.startTime === null) {
        this.startTime = Date.now();
      }
      this.touchesCache.push({
        identifier: touch.identifier,
        clientX: touch.clientX,
        clientY: touch.clientY,
      });
    },
    getTouchFromCacheById(identifier) {
      return this.touchesCache.find(
        (touchCached) => touchCached.identifier === identifier
      );
    },
    removeTouchFromCache(touch) {
      this.touchesCache = this.touchesCache.filter((touchCached) => {
        return touchCached.identifier !== touch.identifier;
      });
      if (this.touchesCache.length === 0) {
        this.reset();
      }
    },
    removeTouchesFromCache(touches) {
      touches.forEach((touch) => {
        return this.removeTouchFromCache(touch);
      });
    },
    detectStartGesture(touchesAll) {
      this.isDetecting = true;
      const touches = this._onlyTouchesCached(touchesAll);
      const touchesAmount = touches.length;

      if (touchesAmount === 1) {
        const touch = touches[0];
        const touchCached = this.getTouchFromCacheById(touch.identifier);
        const gestureDirection = this._getGestureDirection(touch, touchCached);
        if (gestureDirection === "left" || gestureDirection === "right") {
          this.gesture = "horizontalScroll";
        }
        if (gestureDirection === "up" || gestureDirection === "down") {
          this.gesture = "verticalScroll";
        }
      }

      if (touchesAmount === 2) {
        const touch1 = touches[0];
        const touch2 = touches[1];

        const touch1Cached = this.getTouchFromCacheById(touch1.identifier);
        const touch2Cached = this.getTouchFromCacheById(touch2.identifier);

        this.zoomStartCenterX =
          (touch1Cached.clientX + touch2Cached.clientX) / 2;
        this.zoomStartCenterY =
          (touch1Cached.clientY + touch2Cached.clientY) / 2;

        this.zoomStartDistance = this._calcDistance(touch1Cached, touch2Cached);

        this.gesture = "zoom";
      }

      this.isDetecting = false;
      return this.gesture;
    },
    zoom(touches) {
      // const touches = this._onlyTouchesCached(touchesAll);

      if (touches.length < 2) {
        return;
      }

      const startCenterX = this.zoomStartCenterX;
      const startCenterY = this.zoomStartCenterY;
      const startDistance = this.zoomStartDistance;

      if (
        startCenterX === null ||
        startCenterY === null ||
        startDistance === null
      ) {
        return;
      }

      galleryController.disableScrolling();

      const touch1 = touches[0];
      const touch2 = touches[1];

      const currentZoomCenterX = (touch1.clientX + touch2.clientX) / 2;
      const currentZoomCenterY = (touch1.clientY + touch2.clientY) / 2;

      const shiftFromCenterX = (currentZoomCenterX - startCenterX) * 2; // x2 for accelarated movement
      const shiftFromCenterY = (currentZoomCenterY - startCenterY) * 2; // x2 for accelarated movement

      const currentDistance = this._calcDistance(touch1, touch2);
      const scale = this._calcScale(startDistance, currentDistance);

      // Transform the image to make it grow and move with fingers
      const transform = `translate3d(${shiftFromCenterX}px, ${shiftFromCenterY}px, 0) scale(${scale})`;
      const imageElement = galleryController.currentImageElement;

      imageElement.style.transform = transform;
      imageElement.style.WebkitTransform = transform;

      this.endZoom = () => {
        const transform = "translate3d(0px, 0px, 0) scale(1)";
        const transitionDuration = 300;
        imageElement.style.willChange = "transform";
        imageElement.style.transition = `transform ${transitionDuration}ms`;
        imageElement.style.transform = transform;
        imageElement.style.WebkitTransform = transform;
        setTimeout(() => {
          imageElement.style.transition = "";
          imageElement.style.willChange = "";
          galleryController.enableScrolling();
        }, transitionDuration);
        this.reset();
      };
    },
    endZoom: () => {},
    _calcScale(startDistance, currentDistance) {
      const scale = currentDistance / startDistance;
      const validatedScale = Math.min(Math.max(1, scale), 4);
      return validatedScale;
    },
    _onlyTouchesCached(touches) {
      return touches.filter((touch) => this.isTouchInCache(touch));
    },
    _calcDistance(touch1, touch2) {
      return Math.hypot(
        touch1.clientX - touch2.clientX,
        touch1.clientY - touch2.clientY
      );
    },
    _getGestureVelocity(distance) {
      const currentTime = Date.now();
      const timeDelta = currentTime - this.startTime;
      return distance / timeDelta;
    },
    _getGestureDirection(touch, touchCached) {
      const diffX = touch.clientX - touchCached.clientX;
      const diffY = touch.clientY - touchCached.clientY;
      if (diffX >= 0 && diffY >= 0) {
        // right + down
        return Math.abs(diffX) >= Math.abs(diffY) ? "right" : "down";
      }
      if (diffX >= 0 && diffY <= 0) {
        // right + up
        return Math.abs(diffX) >= Math.abs(diffY) ? "right" : "up";
      }
      if (diffX <= 0 && diffY >= 0) {
        // left + down
        return Math.abs(diffX) >= Math.abs(diffY) ? "left" : "down";
      }
      if (diffX <= 0 && diffY <= 0) {
        // left + up
        return Math.abs(diffX) >= Math.abs(diffY) ? "left" : "up";
      }
    },
  });

  const onTouchStart = (event) => {
    if (gestureController.gesture || gestureController.isDetecting) {
      // Stop handling new touch if there is an active gesture
      // OR gesture is detecting RN
      return event.preventDefault();
    }

    const touches = Array.from(event.touches);

    touches.forEach((touch) => {
      // Cache touch
      gestureController.cacheTouch(touch);
    });
  };

  const onTouchMove = (event) => {
    if (gestureController.isDetecting) {
      return;
    }

    let detectedGesture = gestureController.gesture;
    const touches = Array.from(event.touches);

    if (detectedGesture === null) {
      detectedGesture = gestureController.detectStartGesture(touches);
      if (!detectedGesture) {
        event.preventDefault();
        return false;
      }
    }

    if (detectedGesture === "verticalScroll") {
      if (galleryController.mode === "full-screen") {
        event.preventDefault();
        return false;
      }
      return;
    }

    if (gestureController.gesture === "horizontalScroll") {
      return;
    }

    if (gestureController.gesture === "zoom") {
      if (galleryController.mode === "preview") {
        return;
      }
      event.preventDefault();
      return gestureController.zoom(touches);
    }
  };

  const onTouchEnd = (event) => {
    const touchesEnded = _extractEndedTouches(event);

    if (gestureController.gesture === "zoom") {
      gestureController.removeTouchesFromCache(touchesEnded);

      if (galleryController.mode === "preview") {
        return;
      }

      if (gestureController.touchesCache.length < 2) {
        return gestureController.endZoom();
      }
    }

    return gestureController.removeTouchesFromCache(touchesEnded);
  };

  const onHorizontalScroll = (event) => {
    // TODO: debounce update
    _updateCurrentFrameIndex(_calcCurrentFrameIndex(event.target));
  };

  const openGallery = (frameNum) => {
    galleryController.open(frameNum);
  };

  const closeGallery = () => {
    galleryController.close();
  };

  const openFullScreenMode = () => {
    if (gestureController.gesture) {
      return;
    }
    galleryController.openFullScreen();
  };

  const closeFullScreenMode = () => {
    if (gestureController.gesture) {
      return;
    }
    galleryController.closeFullScreen();
  };

  const toNext = () => {
    galleryController.moveToNext({ animate: true });
  };

  const toPrev = () => {
    galleryController.moveToPrev({ animate: true });
  };

  const _updateCurrentFrameIndex = (num) => {
    _currentFrameIndex.value = num;
  };

  const _calcCurrentFrameIndex = (galleryEl) => {
    const newScrollLeft = galleryEl.scrollLeft;
    const imageWidth = galleryController.currentImageElement.offsetWidth;
    const frameWidth = imageWidth + galleryController.frameGap;
    return Math.min(
      Math.round(newScrollLeft / frameWidth),
      galleryController.frameAmount - 1
    );
  };

  const _extractEndedTouches = (touchEvent) => {
    const touchesChanged = Array.from(touchEvent.changedTouches);
    const touchesAll = Array.from(touchEvent.touches);
    const onlyEnded = (touchChanged) => {
      const containsChangedTouches = (touch) => {
        return touch.identifier === touchChanged.identifier;
      };
      return touchesAll.some(containsChangedTouches) === false;
    };
    const touchesEnded = touchesChanged.filter(onlyEnded);
    return touchesEnded;
  };

  const _getFrameScrollLeft = (frame) => {
    const newFrameNum = _getNextFrameNum(frame);
    return _getFrameScrollLeftByFrameNum(newFrameNum);
  };

  const _getFrameScrollLeftByFrameNum = (frameNum) => {
    const imageWidth = galleryController.currentImageElement.offsetWidth;
    const frameWidth = imageWidth + galleryController.frameGap;
    return frameNum * frameWidth;
  };

  const _getNextFrameNum = (frame) => {
    const currentScrollLeft = galleryRef.value.scrollLeft;
    const imageWidth = galleryController.currentImageElement.offsetWidth;
    const frameWidth = imageWidth + galleryController.frameGap;
    let nextFrameNum;
    switch (frame) {
      case "closest":
        nextFrameNum = Math.round(currentScrollLeft / frameWidth);
        return _getValidatedFrameNum(nextFrameNum);
      case "right":
        nextFrameNum = Math.ceil(currentScrollLeft / frameWidth);
        return _getValidatedFrameNum(nextFrameNum);
      case "left":
        nextFrameNum = Math.floor(currentScrollLeft / frameWidth);
        return _getValidatedFrameNum(nextFrameNum);
    }
  };

  const _getValidatedFrameNum = (frameNum) => {
    const frameAmount = galleryController.frameAmount;
    if (frameNum + 1 > frameAmount) {
      return frameAmount - 1;
    }
    if (frameNum < 0) {
      return 0;
    }
    return frameNum;
  };

  const _animateGalleryTo = ({
    startScrollLeft,
    finishScrollLeft,
    duration = 500,
    timingFunction = timingFunctions.easeOutCubic,
    callback = () => {},
  }) => {
    if (startScrollLeft === finishScrollLeft) {
      return;
    }

    const distance = finishScrollLeft - startScrollLeft;
    let startTime;
    let isAborted = false;

    const animateMove = (currentTime) => {
      if (isAborted) {
        return callback();
      }

      if (startTime === undefined) {
        startTime = currentTime;
      }

      const elapsedTime = currentTime - startTime;
      const percentageElapsed = elapsedTime / duration;
      const k = timingFunction(percentageElapsed);
      const shiftScrollLeft = k * distance;
      const newScrollLeft = startScrollLeft + shiftScrollLeft;

      galleryController.moveTo(newScrollLeft);

      if (elapsedTime < duration) {
        return requestAnimationFrame(animateMove);
      } else {
        galleryController.moveTo(finishScrollLeft);
        return callback();
      }
    };

    requestAnimationFrame(animateMove);

    const abort = () => {
      isAborted = true;
    };

    return abort;
  };

  return {
    _currentFrameIndex,
    _mode,
    _isOpened,

    openFullScreenMode,
    closeFullScreenMode,
    openGallery,
    closeGallery,
    toNext,
    toPrev,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onHorizontalScroll,
  };
}
