<template>
  <div class="chip" :class="crossed ? 'chip--crossed' : null">
    <label v-html="label" class="label chip__label"></label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    crossed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.chip {
  height: 40px;
  width: fit-content;
  padding: 0 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 20px;
  background-color: #c7ede6;
}
.chip--crossed {
  text-decoration: line-through;
  opacity: 0.4;
}
.chip__label {
  font-size: 16px;
}
</style>
