<template>
  <div class="schedule-container">
    <div
      class="wrapper-horizontal schedule-container__wrapper-horizontal schedule-container__wrapper-horizontal--column-titles"
    >
      <div
        class="row-title wrapper-horizontal__row-title column-title wrapper-horizontal__column-title cell wrapper-horizontal__cell"
      ></div>
      <div
        v-for="(label, slug) in dayParts"
        :key="slug"
        class="column-title wrapper-horizontal__column-title cell wrapper-horizontal__cell"
      >
        {{ label }}
      </div>
    </div>
    <div
      v-for="(label, slug, i) in weekDays"
      :key="i"
      class="wrapper-horizontal schedule-container__wrapper-horizontal"
    >
      <div
        class="row-title wrapper-horizontal__row-title cell wrapper-horizontal__cell"
      >
        {{ label }}
      </div>
      <div
        v-for="j in 4"
        :key="i.toString() + (j - 1).toString()"
        :class="
          checkIjInScheduleValue(i.toString() + (j - 1).toString())
            ? 'checked'
            : ''
        "
        class="cell wrapper-horizontal__cell"
      ></div>
    </div>
  </div>
</template>

<script>
// import { ref, computed } from "@vue/reactivity";

export default {
  props: {
    scheduleValue: Array,
  },

  setup(props) {
    const dayParts = {
      morning: "Rano",
      afternoon: "Dzien",
      evening: "Wieczór",
      night: "Noc",
    };

    const weekDays = {
      mon: "Pn",
      tue: "Wt",
      wed: "Śr",
      thu: "Cz",
      fri: "Pt",
      sat: "Sb",
      sun: "Nd",
    };

    const checkIjInScheduleValue = (ij) => {
      return props.scheduleValue.some((checkedIj) => checkedIj === ij);
    };

    return {
      dayParts,
      weekDays,
      checkIjInScheduleValue,
    };
  },
};
</script>

<style scoped>
.schedule-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;

  background-color: rgba(227, 170, 229, 0.3);
  border-radius: 10px;

  font: var(--schedule-title--font);
}
.schedule-container__wrapper-horizontal {
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  flex-grow: 1;
}
.schedule-container__wrapper-horizontal--column-titles {
  width: 100%;
  flex-grow: 0;
}
.wrapper-horizontal__cell {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  border-right: 2px solid #fff;
  border-bottom: 2px solid #5fb9fb;
}
.wrapper-horizontal__cell:last-child {
  border-right: unset;
}
.schedule-container__wrapper-horizontal:last-child .wrapper-horizontal__cell {
  border-bottom: unset;
}

.wrapper-horizontal__cell.checked:after {
  margin-top: auto;
  margin-bottom: auto;

  width: 30px;
  height: 30px;
  border-radius: 20px;
  box-sizing: border-box;

  position: absolute;

  background-color: #bfb3f9;
  content: "";
  display: block;
  visibility: visible;
}

.wrapper-horizontal__cell.checked + .wrapper-horizontal__cell.checked:after {
  width: calc(100% + 30px + 2px);
  left: calc(-50%);
  width: calc(100% + 30px + 2px + 1px);
  left: calc(-50% - 16px - 2px);
}

@media (max-width: 1200px) {
  .wrapper-horizontal__cell {
    height: 45px;
  }
  .wrapper-horizontal__cell.checked:after {
    width: 22px;
    height: 22px;
  }
  .wrapper-horizontal__row-title {
    max-width: 43px;
  }
  .wrapper-horizontal__column-title {
    height: 43px;
  }
}
</style>
