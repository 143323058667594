<template>
  <div class="block-text-qoute">
    <SvgIconsQouteComma class="icon block-text-qoute__icon" />
    <p v-html="text" class="text block-text-qoute__text"></p>
  </div>
</template>

<script>
import SvgIconsQouteComma from "@/components/Svg/Icons/SvgIconsQuoteComma";

export default {
  components: { SvgIconsQouteComma },
  props: {
    text: String,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
.block-text-qoute {
  padding: 40px 65px 45px 85px;

  width: 100%;
  height: fit-content;

  display: flex;
  column-gap: 30px;
  justify-content: center;

  font-size: 22px;
  background-color: #c7ede6;
  border-radius: 10px;

  position: relative;
}

.block-text-qoute__icon {
  position: absolute;
  left: 26px;
  width: auto;
  height: 30px;
}

@media (max-width: 1200px) {
  .block-text-qoute {
    padding: 20px 20px 22px 30px;

    width: 100%;
    height: fit-content;

    display: flex;
    column-gap: 30px;
    justify-content: center;

    font-size: 18px;
    background-color: #c7ede6;
    border-radius: 10px;

    position: relative;
  }
  .block-text-qoute__icon {
    position: absolute;
    top: 10px;
    left: 8px;
    width: auto;
    height: 15px;
  }
}
</style>
