<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
  >
    <path
      d="M21.9383 9.00019L23.5508 10.6127L16.1258 18.0377L23.5508 25.4627L21.9383 27.0752L12.9008 18.0377L21.9383 9.00019Z"
      fill="black"
    />
  </svg>
</template>

<style scoped>
.svg-icon path {
  fill: var(--default-font-color);
}
</style>
