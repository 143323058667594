<template>
  <div
    class="container-photo-main"
    :class="isListView ? 'container-photo-main--list-view' : ''"
  >
    <img v-if="src" :src="src" class="img container-photo-main__img" />
    <div
      v-else
      :style="`background-color: ${profileColor}`"
      class="img-empty container-photo-main__img-empty"
    ></div>

    <span v-if="yearsLabel" class="label-age container-photo-main__label-age">{{
      yearsLabel
    }}</span>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  components: {},

  props: {
    src: String,
    yearsLabel: {
      type: String,
      default: "",
    },
    isListView: {
      type: Boolean,
      default: false,
    },
    defaultColorNum: Number,
  },

  setup(props) {
    const PROFILE_COLORS = [
      "#F3C5CE",
      "#FFD5A4",
      "#B9B8FF",
      "#DCD1FF",
      "#FFF1A9",
      "#FFC9C2",
      "#B4CAFE",
      "#CDD0F0",
      "#F3C5CE",
      "#FFD5A4",
    ];

    const profileColor = computed(() => {
      return PROFILE_COLORS[props.defaultColorNum];
    });

    return { profileColor };
  },
};
</script>

<style scoped>
.container-photo-main {
  height: var(--layout-view-block-21--column-width);
  position: relative;
}
.container-photo-main--list-view {
  height: 145px;
  position: relative;
}
.container-photo-main__img,
.container-photo-main__img-empty {
  width: var(--layout-view-block-21--column-width);
  height: var(--layout-view-block-21--column-width);

  object-fit: cover;
  border-radius: 10px;
}
.container-photo-main__img:hover {
  cursor: pointer;
}
.container-photo-main--list-view .container-photo-main__img,
.container-photo-main--list-view .container-photo-main__img-empty {
  width: 145px;
  height: 145px;
}
/* .container-photo-main__img-empty {
  background-color: aqua;
} */
.container-photo-main__label-age {
  padding: 4px 12px;
  position: absolute;
  left: 0;
  bottom: 20px;

  background-color: #000;
  border-radius: 0 20px 20px 0;

  color: #fff;
  text-align: center;
}
.container-photo-main--list-view .container-photo-main__label-age {
  bottom: 10px;
  padding: 2px 8px 2px 6px;
  font: 300 12px var(--default-font-family);
}
</style>
