<template>
  <div class="block">
    <BaseH3 v-html="blockTitle" />
    <BaseGroupChip :items="items" :items-crossed="itemsCrossed" />
  </div>
</template>

<script>
import BaseGroupChip from "./UI/Base/BaseGroupChip.vue";
import BaseH3 from "./UI/Base/BaseH3.vue";

export default {
  components: { BaseGroupChip, BaseH3 },
  props: {
    items: Array,
    itemsCrossed: {
      type: Array,
      default: () => [],
    },
    blockTitle: String,
  },
};
</script>

<style scoped>
.block {
  width: 100%;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  row-gap: 20px;
}
</style>
