<template>
  <Teleport to="body">
    <div class="root-container" :opened="isOpened">
      <div class="top-container">
        <div v-if="currentFrameString" class="frame-num">
          {{ currentFrameString }}
        </div>
        <div class="close-icon">
          <BaseButtonIconCross @click="close" />
        </div>
      </div>
      <div class="middle-container">
        <div class="arrow">
          <BaseButtonIconNavigateBack
            v-show="canNavigatePrev"
            @click="toPrev"
          />
        </div>
        <div ref="galleryRef" class="photo-gallery">
          <div class="photo-main">
            <img
              v-if="photoMainImg"
              :src="photoMainImg.src"
              :srcset="photoMainImg.srcsetJpg"
              class="img img-0"
            />
            <div
              v-else
              :style="`background-color: ${profileColor}`"
              class="img img-0"
            ></div>
          </div>
          <img
            v-for="(image, n) in photoGalleryImgs"
            :key="image.src"
            :src="image.src"
            :srcset="image.srcsetJpg"
            :class="['img', `img-${n + 1}`]"
          />
          <div id="galleryWider"></div>
        </div>
        <div class="arrow">
          <BaseButtonIconNavigateNext
            v-show="canNavigateNext"
            @click="toNext"
          />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";

// Composables
import useProfileColor from "@/composables/useProfileColor";
import useViewportUnits from "@/composables/useViewportUnits";
import usePhotoGallery from "@/composables/usePhotoGallery";

// Components
import BaseButtonIconCross from "@/components/UI/Base/BaseButtonIconCross.vue";
import BaseButtonIconNavigateBack from "@/components/UI/Base/BaseButtonIconNavigateBack.vue";
import BaseButtonIconNavigateNext from "@/components/UI/Base/BaseButtonIconNavigateNext.vue";

export default {
  components: {
    BaseButtonIconCross,
    BaseButtonIconNavigateBack,
    BaseButtonIconNavigateNext,
  },

  props: {
    photoMainImg: Object,
    photoGalleryImgs: Array,
    defaultColorNum: Number,
  },

  expose: ["open"],

  setup(props) {
    // TODO: take column-gap from computedStyle or calculate from width(s)
    const FRAME_GAP = 20;

    const galleryRef = ref(null);

    const frameAmount = computed(() => {
      return props.photoGalleryImgs.length + 1;
    });
    const currentImageElement = computed(() => {
      let index;
      if (!_currentFrameIndex?.value) {
        index = 0;
      } else {
        index = _currentFrameIndex.value;
      }
      return document.querySelector(`.photo-gallery .img-${index}`);
    });

    const galleryOptions = {
      modeInitial: "full-screen",
      isOpenedInitial: false,
      frameGap: FRAME_GAP,
      frameAmountRef: frameAmount,
      currentImageElementRef: currentImageElement,
    };

    const {
      _isOpened,
      _currentFrameIndex,
      openGallery,
      closeGallery,
      toNext,
      toPrev,
    } = usePhotoGallery(galleryRef, galleryOptions);

    const currentFrameString = computed(() => {
      if (frameAmount.value === 1) {
        return "";
      }
      return `${_currentFrameIndex.value + 1} / ${frameAmount.value}`;
    });

    const canNavigateNext = computed(() => {
      return _currentFrameIndex.value < frameAmount.value - 1;
    });

    const canNavigatePrev = computed(() => {
      return _currentFrameIndex.value > 0;
    });

    const open = (frameNum) => {
      if (!props.photoMainImg && props.photoGalleryImgs.length === 0) {
        return;
      }
      document.body.classList.add("noscroll");
      openGallery(frameNum);
    };

    const close = () => {
      document.body.classList.remove("noscroll");
      closeGallery();
    };

    const handleKeyup = ({ code }) => {
      switch (code) {
        case "Space":
          return toNext();
        case "ArrowRight":
          return toNext();
        case "ArrowLeft":
          return toPrev();
      }
    };

    onMounted(() => {
      window.addEventListener("keyup", handleKeyup);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("keyup", handleKeyup);
    });

    return {
      isOpened: _isOpened,
      frameAmount,
      currentFrameIndex: _currentFrameIndex,
      currentFrameString,
      canNavigateNext,
      canNavigatePrev,

      galleryRef,

      open,
      close,
      toNext,
      toPrev,

      ...useProfileColor(props.defaultColorNum),
      ...useViewportUnits(),
    };
  },
};
</script>

<style scoped>
.root-container {
  position: absolute;
  top: -100vh;
  left: 0px;
  z-index: 99999999999999999999;
  width: 100vw;
  height: 100vh;
  padding-bottom: var(--header-default--height);
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #111;
  opacity: 0;
}
.root-container[opened="true"] {
  position: fixed;
  top: 0px;
  opacity: 1;
}

.top-container {
  padding-left: var(--layout-8col--column-width);
  width: var(--layout-default--width);
  min-height: var(--header-default--height);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.top-container .frame-num {
  margin: auto;
  font: var(--font-small);
  color: #fff;
}
.top-container .close-icon {
  width: var(--layout-8col--column-width);
  display: flex;
  justify-content: center;
}
.top-container .close-icon :deep(.svg-icon) path {
  fill: #fff;
}
.top-container .close-icon :deep(.button-cross) {
  opacity: 0.4;
}
.top-container .close-icon :deep(.button-cross):hover {
  background-color: rgba(255, 255, 255, 0.05);
  opacity: 1;
}
.middle-container {
  width: var(--layout-default--width);
  height: calc(100vh - 2 * var(--header-default--height));
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: var(--layout-8col--column-gap);
}
.middle-container .arrow {
  width: var(--layout-8col--column-width);
  display: flex;
  justify-content: center;
}
.middle-container .arrow :deep(.svg-icon) path {
  fill: #fff;
}
.middle-container .photo-gallery {
  width: 100%;
  height: 100%;
  --photo-gallery--column-gap: 20px;
  display: flex;
  align-items: center;
  column-gap: var(--photo-gallery--column-gap);
  overflow: hidden;
}
.middle-container .photo-gallery .img::selection {
  background: transparent;
}
#galleryWider {
  margin-left: calc(-1 * var(--photo-gallery--column-gap));
}
.photo-gallery .img {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: contain;
}
.photo-gallery .photo-main {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}
</style>
