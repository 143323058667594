<template>
  <div class="group-item-icon-label">
    <BaseGroupIconLabelItem
      v-for="item in items"
      :key="item.slug"
      :label="item.label"
      :icon-component="item.iconComponent"
    />
  </div>
</template>

<script>
import BaseGroupIconLabelItem from "./BaseGroupIconLabelItem.vue";

export default {
  components: { BaseGroupIconLabelItem },
  props: {
    items: Array,
  },
};
</script>

<style scoped>
.group-item-icon-label {
  width: 100%;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  row-gap: 30px;
  column-gap: 50px;
}
</style>
