<template>
  <div class="block-text-main">
    <p>{{ readyText }}</p>
    <ButtonLinkMore
      v-if="isTextOverflow"
      :isLess="isBlockExpanded"
      :size="isMobile ? 'small' : 'medium'"
      @click="isBlockExpanded = !isBlockExpanded"
    />
  </div>
</template>

<script>
import { computed, toRef } from "@vue/reactivity";

import ButtonLinkMore from "@/components/UI/ButtonLinkMore";
import useBlockTextExpandable from "@/composables/useBlockTextExpandable";
import useIsMobile from "@/composables/useIsMobile";

export default {
  components: { ButtonLinkMore },

  props: {
    text: String,
    maxLength: { type: Number, default: 200 },
  },

  setup(props) {
    const { isBlockExpanded, isTextOverflow, readyText, blockBorderRadius } =
      useBlockTextExpandable({
        textRef: toRef(props, "text"),
        maxLengthRef: toRef(props, "maxLength"),
      });

    const { isMobile } = useIsMobile();

    const blockPadding = computed(() => {
      if (isMobile.value) {
        return isTextOverflow.value
          ? "20px 30px 7px 30px"
          : "20px 30px 20px 30px";
      } else {
        return isTextOverflow.value
          ? "50px 60px 20px 60px"
          : "50px 60px 50px 60px";
      }
    });

    return {
      isBlockExpanded,
      isTextOverflow,
      readyText,
      blockBorderRadius,
      isMobile,
      blockPadding,
    };
  },
};
</script>

<style scoped>
.block-text-main {
  padding: v-bind(blockPadding);

  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 10px;

  background-color: #fff;
  border-radius: v-bind(blockBorderRadius);
}
.block-text-main p {
  white-space: pre-line;
  font-size: 22px;
}
/* .better_accessability p {
  font-family: "Source Serif Pro";
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.003em;
  color: rgba(22, 32, 94, 0.9);
} */

@media (max-width: 1200px) {
  .block-text-main {
    row-gap: 0;
  }
  .block-text-main p {
    font-size: var(--default-font-size);
  }
}
</style>
